import Image1 from '../../assets/avataaars1.png';
import Image2 from '../../assets/avataaars2.png';
import Image3 from '../../assets/avataaars3.png';

export const Data = [
    {
        id: 1,
        image: Image1,
        name: "Mariam Nientao",
        testimonial: "Mr Coulibaly a dépassé nos attentes en livrant un code propre et performant, améliorant ainsi considérablement notre site web."
    },
    {
        id: 2,
        image: Image2,
        name: "Team Teccart",
        testimonial: "Travailler avec Mr Coulibaly a été une expérience très positive grâce à sa communication transparente et son engagement envers la satisfaction du client."
    },
    {
        id: 3,
        image: Image3,
        name: "GIH Mali",
        testimonial: "Grâce à Mr Coulibaly, nous avons pu réaliser des projets ambitieux dans des délais serrés, grâce à sa maîtrise des technologies modernes et à sa créativité."
    }
]