import React from 'react'

const WorkItem = ({ item }) => {
    return (
        <div className='work__card' key={item.id}>
            <img src={item.image} alt='work_image' className='work__image' />
            <h3 className="work__title">{item.title}</h3>
            <a href={item.link} target='_blank' className="work__button" rel="noreferrer">
                Plus <i className="bx bx-right-arrow-alt work__button-icon"></i>
            </a>
        </div>
    )
}

export default WorkItem