import React, { useState } from 'react';
import './services.css';

const Services = () => {

    const [toggleState, setToggleState] = useState(0);

    const toggleTab = (index) => {
        setToggleState(index);
    }

    return (
        <section className="services section" id="services">
            <h2 className="section__title">Services</h2>
            <span className="section__subtitle">Ce que j'offre</span>

            <div className="services__container container grid">
                <div className="services__content">
                    <div>
                        <i className='uil uil-arrow services__icon'></i>
                        <h3 className="services__title">Développement <br /> Web/Mobile</h3>
                    </div>

                    <span className='services__button' onClick={() => toggleTab(1)}>
                        Voir plus <i className='uil uil-arrow-right services__button-icon'></i>
                    </span>

                    <div className={toggleState === 1 ? "services__modal active-modal" : "services__modal"}>
                        <div className="services__modal-content">
                            <i className='uil uil-times services__modal-close' onClick={() => toggleTab(0)}></i>

                            <h3 className='services__modal-title'>Développement d'application Web et Mobile</h3>
                            <p className='services__modal-description'>
                                Service avec plus de 3 ans d'expérience.
                                Fournir un travail de qualité aux clients et aux entreprises.
                            </p>

                            <ul className="services__modal-serives grid">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle serivces__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Je developpe des applications web et mobiles.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle serivces__modal-icon"></i>
                                    <p className="services__modal-info">
                                        J'assurance la maintenance.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle serivces__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Je fournis le soutien informatique.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="services__content">
                    <div>
                        <i className='uil uil-web-grid services__icon'></i>
                        <h3 className="services__title">Ui/Ux <br /> Designer</h3>
                    </div>

                    <span className='services__button' onClick={() => toggleTab(2)}>
                        Voir plus <i className='uil uil-arrow-right services__button-icon'></i>
                    </span>

                    <div className={toggleState === 2 ? "services__modal active-modal" : "services__modal"}>
                        <div className="services__modal-content">
                            <i className='uil uil-times services__modal-close' onClick={() => toggleTab(0)}></i>

                            <h3 className='services__modal-title'>Ui/Ux Designer</h3>
                            <p className='services__modal-description'>
                                Service avec plus de 3 ans d'expérience.
                                Fournir un travail de qualité aux clients et aux entreprises.
                            </p>

                            <ul className="services__modal-serives grid">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle serivces__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Je developpe des interfaces utilisateurs.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle serivces__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Je fais la maquettes des interfaces utilisateurs.
                                    </p>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>

                <div className="services__content">
                    <div>
                        <i className='uil uil-edit services__icon'></i>
                        <h3 className="services__title">Visual <br /> Designer </h3>
                    </div>

                    <span className='services__button' onClick={() => toggleTab(3)}>
                        Voir plus <i className='uil uil-arrow-right services__button-icon'></i>
                    </span>

                    <div className={toggleState === 3 ? "services__modal active-modal" : "services__modal"}>
                        <div className="services__modal-content">
                            <i className='uil uil-times services__modal-close' onClick={() => toggleTab(0)}></i>

                            <h3 className='services__modal-title'>Designer <br /> Graphique</h3>
                            <p className='services__modal-description'>
                                Service avec plus de 3 ans d'expérience.
                                Fournir un travail de qualité aux clients et aux entreprises.
                            </p>

                            <ul className="services__modal-serives grid">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle serivces__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Je dessine vos logos.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle serivces__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Je fais la conception des éditions, des affiches.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle serivces__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Je positionne la marque de votre entreprise.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Services