import React from 'react';
import './skills.css';
import Frontend from './Frontend';
import Backend from './Backend';

const Skills = () => {
    return (
        <section className='section skills' id='competences'>
            <h2 className='section__title'>Competences</h2>
            <span className='section__subtitle'>Mon niveau technique</span>

            <div className="skills__container container grid">
                <Frontend />
                <Backend />
            </div>
        </section>
    )
}

export default Skills