import React from 'react';
import './footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__container container">
                <h1 className="footer__title">Karamoko Coulibaly</h1>

                <ul className="footer__list">
                    <li>
                        <a href="#a-propos" className="footer__link">À propos de moi</a>
                    </li>
                    <li>
                        <a href="#portfolio" className="footer__link">Projets</a>
                    </li>
                    <li>
                        <a href="#temoignages" className="footer__link">Témoignages</a>
                    </li>
                </ul>

                <div className="footer__social">
                    <a href="https://www.instagram.com/kvrv223" className='footer__social-link' target='_blank' rel="noreferrer">
                        <i className='bx bxl-instagram'></i>
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=100014655874755" className='footer__social-link' target='_blank' rel="noreferrer">
                        <i className="bx bxl-facebook"></i>
                    </a>
                    <a href="https://twitter.com/kvrv223" className='footer__social-link' target='_blank' rel="noreferrer">
                        <svg
                            style={{ height: "15px", color: 'white' }}
                            xmlns="http://www.w3.org/2000/svg"
                            shapeRendering="geometricPrecision"
                            textRendering="geometricPrecision"
                            imageRendering="optimizeQuality"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            viewBox="0 0 512 462.799">
                            <path
                                fill="#fff"
                                fillRule="nonzero"
                                d="M403.229 0h78.506L310.219 196.04 512 462.799H354.002L230.261 301.007 88.669 462.799h-78.56l183.455-209.683L0 0h161.999l111.856 147.88L403.229 0zm-27.556 415.805h43.505L138.363 44.527h-46.68l283.99 371.278z"
                            />
                        </svg>
                    </a>
                </div>

                <span className="footer__copy">
                    &#169; KaramokoCoulibaly. Tous droits réservés
                </span>
            </div>
        </footer>
    )
}

export default Footer