import React from 'react'

const Info = () => {
    return (
        <div className='about__info grid'>
            <div className="about__box">
                <i className='bx bx-award about__icon'></i>
                <h3 className='about__title'>Expérience</h3>
                <span className="about__subtitle">3 ans</span>
            </div>

            <div className="about__box">
                <i className='bx bx-briefcase-alt about__icon'></i>
                <h3 className='about__title'>Complétés</h3>
                <span className="about__subtitle">20+ projets</span>
            </div>

            <div className="about__box">
                <i className='bx bx-support about__icon'></i>
                <h3 className='about__title'>Support</h3>
                <span className="about__subtitle">En ligne 7/7</span>
            </div>
        </div>
    )
}

export default Info