import React, { useState } from 'react';
import './qualifications.css';

const Qualifications = () => {

    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    }

    return (
        <section className="qualifications section">
            <h2 className="section__title">Qualifications</h2>
            <span className="section__subtitle">Mon parcours personnel</span>

            <div className="qualifications__container container">
                <div className="qualifications__tabs">
                    <div className={toggleState === 1 ? "qualifications__button qualifications__active button__flex" : "qualifications__button button__flex"}
                        onClick={() => toggleTab(1)}>
                        <i className="uil uil-graduation-cap qualifications__icon"></i>Éducation
                    </div>

                    <div className={toggleState === 2 ? "qualifications__button qualifications__active button__flex" : "qualifications__button button__flex"}
                        onClick={() => toggleTab(2)}>
                        <i className="uil uil-briefcase-alt qualifications__icon"></i>Expérience
                    </div>
                </div>

                <div className="qualifications__sections">
                    <div className={toggleState === 1 ? "qualifications__content qualifications__content-active" : "qualifications__content"}>
                        <div className="qualifications__data">
                            <div>
                                <h3 className="qualifications__title">Informatique</h3>
                                <span className="qualifications__subtitle">Baccalauréat - ULaval</span>
                                <div className="qualifications__calender">
                                    <i className="uil uil-calendar-alt"></i> 2023 - Présent
                                </div>
                            </div>

                            <div>
                                <span className="qualifications__rounder"></span>
                                <span className="qualifications__line"></span>
                            </div>
                        </div>

                        <div className="qualifications__data">
                            <div></div>
                            <div>
                                <span className="qualifications__rounder"></span>
                                <span className="qualifications__line"></span>
                            </div>

                            <div>
                                <h3 className="qualifications__title">Programmation web et mobile</h3>
                                <span className="qualifications__subtitle">Diplôme d'Étude Collegiale - Teccart</span>
                                <div className="qualifications__calender">
                                    <i className="uil uil-calendar-alt"></i> 2020 - 2022
                                </div>
                            </div>
                        </div>


                        <div className="qualifications__data">
                            <div>
                                <h3 className="qualifications__title">Management des affaires</h3>
                                <span className="qualifications__subtitle">Licence - SupMTI</span>
                                <div className="qualifications__calender">
                                    <i className="uil uil-calendar-alt"></i> 2015 - 2019
                                </div>
                            </div>

                            <div>
                                <span className="qualifications__rounder"></span>
                                <span className="qualifications__line"></span>
                            </div>
                        </div>

                        <div className="qualifications__data">
                            <div></div>
                            <div>
                                <span className="qualifications__rounder"></span>
                                <span className="qualifications__line"></span>
                            </div>

                            <div>
                                <h3 className="qualifications__title">Baccalaureat français</h3>
                                <span className="qualifications__subtitle">Collège Oumar Bah</span>
                                <div className="qualifications__calender">
                                    <i className="uil uil-calendar-alt"></i> 2013 - 2015
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={toggleState === 2 ? "qualifications__content qualifications__content-active" : "qualifications__content"}>
                        <div className="qualifications__data">
                            <div>
                                <h3 className="qualifications__title">Développeur Full Stack</h3>
                                <span className="qualifications__subtitle">SyntheseAI</span>
                                <div className="qualifications__calender">
                                    <i className="uil uil-calendar-alt"></i> 2022 - Présent
                                </div>
                            </div>

                            <div>
                                <span className="qualifications__rounder"></span>
                                <span className="qualifications__line"></span>
                            </div>
                        </div>

                        <div className="qualifications__data">
                            <div></div>
                            <div>
                                <span className="qualifications__rounder"></span>
                                <span className="qualifications__line"></span>
                            </div>

                            <div>
                                <h3 className="qualifications__title">Stagiaire Fullstack</h3>
                                <span className="qualifications__subtitle">Institut Teccart</span>
                                <div className="qualifications__calender">
                                    <i className="uil uil-calendar-alt"></i> 2021 - 2022
                                </div>
                            </div>
                        </div>


                        <div className="qualifications__data">
                            <div>
                                <h3 className="qualifications__title">Fondateur / Développeur Fullstack</h3>
                                <span className="qualifications__subtitle">IKA Technologies & Services</span>
                                <div className="qualifications__calender">
                                    <i className="uil uil-calendar-alt"></i> 2019 - Présent
                                </div>
                            </div>

                            <div>
                                <span className="qualifications__rounder"></span>
                                <span className="qualifications__line"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </section>
    )
}

export default Qualifications