import React from 'react';
import './testimonials.css';

import { Data } from './Data';

// import Swiper core and required modules
import { Pagination } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Testimonials = () => {
    return (
        <section className="testimonial container section" id='temoignages'>
            <h2 className="section__title">Mes clients disent</h2>
            <span className="section__subtitle">Témoignages</span>

            <Swiper className="testimonial__container"
                loop
                grabCursor
                spaceBetween={24}
                pagination={{
                    clickable: true
                }}
                breakpoints={{
                    576: {
                        slidesPerView: 2,
                        spaceBetween: 40
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 48
                    }
                }}

                modules={[Pagination]}
            >
                {Data.map(({ id, image, name, testimonial }) => {
                    return (
                        <SwiperSlide className="testimonial__card" key={id}>
                            <img src={image} alt={name} className='testimonial__img' />
                            <h3 className="testimonial__name">{name}</h3>
                            <p className="testimonial__description">{testimonial}</p>
                        </SwiperSlide>
                    )
                })}

            </Swiper>
        </section>
    )
}

export default Testimonials