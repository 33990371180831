import Work1 from '../../assets/work1.png'
import Work2 from '../../assets/work2.png'
import Work3 from '../../assets/work3.png'
import Work4 from '../../assets/work4.jpg'
import Work5 from '../../assets/work5.jpg'

export const projectsData = [
    {
        id: 1,
        image: Work1,
        title: 'GeDocs',
        description: '',
        category: 'web',
        link: 'https://gedocs.netlify.app'
    },
    {
        id: 2,
        image: Work2,
        title: 'IkaSchool',
        description: '',
        category: 'web',
        link: 'https://csk-mali.school/'
    },
    {
        id: 3,
        image: Work3,
        title: 'Universelle-Beauté',
        description: '',
        category: 'web',
        link: 'https://universelle-beaute.netlify.app/'
    },
    {
        id: 4,
        image: Work4,
        title: 'Swap',
        description: '',
        category: 'mobile',
        link: 'https://github.com/karaIT123/React-Native-Swap'
    },
    {
        id: 5,
        image: Work5,
        title: 'ToDo',
        description: '',
        category: 'mobile',
        link: 'https://github.com/karaIT123/Flutter-ToDo'
    },
]

export const projectsNav = [
    {
        name: 'tout'
    },
    {
        name: 'web'
    },
    {
        name: 'mobile'
    }
]