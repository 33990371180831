import React from 'react'

const Social = () => {
    return (
        <div className='home__social'>
            <a href="https://www.instagram.com/kvrv223/" className='home__social-icon' target='_blank' rel="noreferrer">
                <i className='uil uil-instagram'></i>
            </a>
            <a href="https://www.linkedin.com/in/karamokocoulibaly/" className='home__social-icon' target='_blank' rel="noreferrer">
                <i className="uil uil-linkedin"></i>
            </a>
            <a href="https://github.com/karaIT123?tab=repositories" className='home__social-icon' target='_blank' rel="noreferrer">
                <i className="uil uil-github"></i>
            </a>
        </div>
    )
}

export default Social