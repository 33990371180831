import React, { useEffect, useState } from 'react'
import { projectsData, projectsNav } from './Data'
import WorkItem from './WorkItem'

const Works = () => {
    const [item, setItem] = useState({ name: 'tout' })
    const [projects, setProjects] = useState([])
    const [active, setActive] = useState(0)

    useEffect(() => {
        if (item.name === "tout") {
            setProjects(projectsData)
        }
        else {
            const filteredProjects = projectsData.filter((project) => {
                return project.category.toLowerCase() === item.name;
            })
            setProjects(filteredProjects)
        }
    }, [item])

    const handleClick = (e, index) => {
        setItem({ name: e.target.textContent.toLowerCase() });
        setActive(index);
    }

    return (
        <div>
            <div className="work__filters">
                {projectsNav.map((item, index) => {
                    return (
                        <span onClick={(e) => {
                            handleClick(e, index)
                        }}
                            className={`${active === index ? 'active-work' : ''} work__item`}>
                            {item.name}
                        </span>
                    )
                })}
            </div>

            <div className="work__container container grid">
                {projects.map((item) => {
                    return <WorkItem item={item} key={item.id} />
                })}
            </div>
        </div>
    )
}

export default Works